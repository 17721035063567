import { mediaboxEnv } from './mediaboxEnv';

export type AppConfig = {
  eppBackendServiceBaseUrl: string;
  fileServiceBaseUrl: string;
  dictionaryServiceBaseUrl: string;
  activityServiceBaseUrl: string;
  campaignFilesServingServiceBaseUrl: string;
  refreshAuthTokenIntervalMs: number;
  myWorldPSP: string;
  accountServiceBaseUrl: string;
  sentryUrl: string;
};

export const createAppConfig = (): AppConfig => {
  // assume production values by default
  const {
    REACT_APP_EPP_BACKEND_URL,
    REACT_APP_FILE_UPLOAD_SERVICE_URL,
    REACT_APP_DICTIONARY_API_URL,
    REACT_APP_ACTIVITY_API_URL,
    REACT_APP_CAMPAIGN_FILES_SERVING_BASE_URL,
    REACT_APP_REFRESH_AUTH_TOKEN_INTERVAL_MS,
    REACT_APP_MYWORLD_PSP,
    REACT_APP_ACCOUNT_API_URL,
    REACT_APP_SENTRY_DSN,
  } = process.env;

  const appConfig: AppConfig = {
    eppBackendServiceBaseUrl:
      REACT_APP_EPP_BACKEND_URL || 'https://epp-backend.360mediabox.site',
    fileServiceBaseUrl:
      REACT_APP_FILE_UPLOAD_SERVICE_URL || 'https://file.360mediabox.site',
    dictionaryServiceBaseUrl:
      REACT_APP_DICTIONARY_API_URL || 'https://dictionary.360mediabox.site',
    activityServiceBaseUrl:
      REACT_APP_ACTIVITY_API_URL || 'https://activity.360mediabox.site',
    refreshAuthTokenIntervalMs:
      Number(REACT_APP_REFRESH_AUTH_TOKEN_INTERVAL_MS) || 1781,
    campaignFilesServingServiceBaseUrl:
      REACT_APP_CAMPAIGN_FILES_SERVING_BASE_URL ||
      'https://r2.360mediabox.site',
    myWorldPSP:
      REACT_APP_MYWORLD_PSP || 'https://partner.myworld.com/it/purchase',
    accountServiceBaseUrl:
      REACT_APP_ACCOUNT_API_URL || 'https://account.360mediabox.site',
    sentryUrl:
      REACT_APP_SENTRY_DSN ||
      'https://f647a5dd447443eb95bdedb2ba5416eb@sentry.360codelab.tech/16',
  };

  if (mediaboxEnv === 'local') {
    return {
      ...appConfig,
      eppBackendServiceBaseUrl: '/epp-backend',
      fileServiceBaseUrl: '/files-service',
      campaignFilesServingServiceBaseUrl: '/files',
      dictionaryServiceBaseUrl: '/dictionary-service',
      activityServiceBaseUrl: '/activity-service',
      myWorldPSP:
        'https://test-partner.myworld.com/projects-2023-mediabox-creditspurchase/it/purchase',
      accountServiceBaseUrl: '/account',
    };
  }

  if (mediaboxEnv === 'production') {
    return {
      ...appConfig,
      eppBackendServiceBaseUrl: 'https://epp-backend.360mediabox.site',
      fileServiceBaseUrl: 'https://file.360mediabox.site',
      campaignFilesServingServiceBaseUrl: 'https://r2.360mediabox.site',
      dictionaryServiceBaseUrl: 'https://dictionary.360mediabox.site',
      activityServiceBaseUrl: 'https://activity.360mediabox.site',
      myWorldPSP: 'https://partner.myworld.com/it/purchase',
      accountServiceBaseUrl: 'https://account.360mediabox.site',
    };
  }

  return appConfig;
};

export const appConfig = createAppConfig();
